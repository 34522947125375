.header-wrapper {
  box-shadow: 0 10px 7px -7px #000;
}
.bg {
  /* background-image: url('../../assets/images/Decorative/HeaderBg.svg'); */
  /* background: var(--clr-bg-dark); */

  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

.bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: var(--clr-bg-dark-transp); */
  background-image: url('../../assets/images/Header/bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
/* background: radial-gradient(circle, rgba(13,83,158,0.2638305322128851) 0%, rgba(13,83,158,0.5943627450980392) 53%, rgba(13,83,158,1) 100%); */
z-index: -2;
}