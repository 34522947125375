.about-grid {
  display: grid;
  grid-template-columns: 1fr 40vw;
  align-items: center;
  column-gap: 1rem;
  position: relative;
}

.about-image {
  background-color: var(--clr-bg-dark);
  box-shadow: 0 0 0px 10px white, 0 0 0px 30px var(--clr-bg-dark);
  position: relative;
  left: 20px;
}

.about-para {
  position: relative;
}

.about-para::before {
  content: '';
  position: absolute;
  width: 2000px;
  height: 140%;
  background-color: var(--clr-bg-dark);
  top: -20%;
  left: -40%;
  z-index: -1;
}

@media (min-width: 575px) and (max-width: 989px) {}

@media (max-width: 575px) {
  .about-grid {
    display: block;
  }

  .about-para::before {
    height: 120%;
    top: -10%;
  }

  .about-para {
    margin-bottom: 10px;
  }

  .about-image {
    left: 0;
    box-shadow: 0 0 0px 5px white, 0 0 0px 8px var(--clr-bg-dark);
  }

}