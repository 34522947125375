.f-wrapper {
  /* color: #fff; */
  color: #fff;
  position: relative;
  bottom: 0;
}

.f-wrapper::before {
  content: '';
  /* background-image: url('../../assets/images/Decorative/HeaderBg.svg'); */
  background-size: cover;
  background-color: var(--clr-bg-darkest);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.footer-grid {
  display: grid;
  grid-template-columns: 100px 700px 200px;
  justify-content: center;
  gap: 50px;
}

/* .f-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--clr-bg-dark-transp);
  mix-blend-mode: multiply;
  z-index: -1;
} */

.footer-col {
  /* max-width: 30ch; */
}

.footer-col .address {
  max-width: 40ch;
}

.f-title {
  position: relative;
  left: 2rem;
  width: fit-content;
}

.f-title.contact {
  width: auto;
}

.f-title::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  top: 100%;
  left: 0;
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.57) 50%, rgba(255, 255, 255, 0) 100%);
  z-index: 2;
}

.f-row {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 10px;
  align-items: flex-start;
  justify-content: center;
}

.f-row:not(:last-child) {
  margin-bottom: 10px;
}

.f-icon {
  position: relative;
  top: 5px;
}

.f-row a:hover {
  text-decoration: underline;
}

.rights-line {
  border-top: 1px solid #fff;
  padding-block: 10px;
}

.rights-line>div {
  position: relative;
  font-size: 1rem;
}

.rights-line>div:not(:last-child)::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  right: -10px;
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.57) 50%, rgba(255, 255, 255, 0) 100%);
  z-index: 2;
}

@media (max-width: 1200px) {

  .footer-grid {
    grid-template-columns: 100px 1fr 200px;
  }
}

@media (max-width: 989px) {

  .f-title.f-title.contact {
    left: 0;
  }

  .f-title.timings {
    left: 0;
  }

  .footer-grid {
    grid-template-columns: 120px 1fr auto;
    grid-template-rows: auto 1fr auto;
    max-width: 80%;
    margin-inline: auto;
    gap: 10px;
  }

  .grid-area-1 {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .grid-area-3 {
    grid-column: 3/4;
    grid-row: 1/2;
  }

  .grid-area-2 {
    grid-column: 1/4;
    grid-row: 2/3;
  }

}

@media (max-width: 575px) {
  .footer-grid {
    max-width: 100%;
    gap: 20px;
  }

  .rights-line>div:not(:last-child)::after {
    width: 100%;
    height: 1px;
    top: 100%;
    right: 0;
  }

  .rights-line div {
    font-size: 0.8rem;
  }
}