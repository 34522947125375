.moto-grid {
  display: grid;
  grid-template-columns: 0.7fr 1.3fr;
  align-items: center;
  column-gap: 1rem;
  position: relative;
}
.moto-image {
  background-color: var(--clr-bg-dark);
  box-shadow: 0 0 0px 10px white, 0 0 0px 30px var(--clr-bg-dark);
  position: relative;
  left: 20px;
  z-index: -1;
  isolation: isolate;
  width: 100%;
}

.moto-para {
  position: relative;
  /* background-color: var(--clr-bg-dark); */
}

.moto-para::before {
  content: '';
  position: absolute;
  width: 2000px;
  height: 140%;
  background-color: var(--clr-bg-dark);
  top: -20%;
  left: -10%;
  z-index: -2;
}

@media (max-width: 989px) {
  .moto-grid {
    display: block;
  }

  .moto-image {
    left: 0;
    box-shadow: none;
  }

  .moto-image img {
    width: 100vw;
  }
}