.keyh-wrapper {

}

.keyh-box {
  border: 10px solid var(--clr-bg-dark);
  border-top-left-radius: 4rem;
  border-bottom-right-radius: 4rem;
  position: relative;
  overflow: hidden;
}




.keyh-box .arcs {
  position: absolute;
  top: -267px;
  right: -155px;
  width: 450px;
  height: 450px;
  opacity: 0.2;
  background-repeat: no-repeat;
  z-index: -1;
}

.keyh-content .line {
  
  font-weight: bold;
  color: var(--clr-bg-dark);
  padding-block: 4px;
  position: relative;
  left: 10px;
}

.keyh-content .line::before {
  content: '☛';
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 5px;
  left: -1.6rem;
  transform: scale(1.5);
}

@media (max-width: 575px) {
  .keyh-box {
    border-top-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
  }

  .keyh-content .line::before {
    width: 3rem;
    height: 3rem;
    left: -0.8rem;
    top: 0;
    top: 10px;
    transform: scale(1.5);
  }
}