@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Cabin';
  /* src: url('./assets/fonts/Cabin.ttf'); */
  src: url('./assets/fonts/Poppins-Regular.ttf');
  font-weight: normal;
    font-style: normal;
}

body {
  margin: 0;
  font-family: "Cabin", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-snap-type: y mandatory;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 
.layout {
  background-image: url('./assets/images/Decorative/WhiteBg.svg');
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: -2;
}

.layout::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.045) 0%, rgba(255, 255, 255, 0.802) 19%, rgb(255, 255, 255) 53%, rgba(255, 255, 255, 0.804) 81%, rgba(255, 255, 255, 0) 100%);;
  z-index: -1;
} */

