.cu-content {
  display: grid;
  grid-template-columns: 40vw 1fr;
  height: 100%;
}

.strip {
  background-color: var(--clr-bg-dark-medium);
  color: #fff;
  padding-block: 10px;
}


.contact-wrapper {
  background-image: url('../../assets/images/ContactUs/cu-whole-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  background-position: center;
  /* min-height: 80vh; */
}

.cu-right-wrapper {
  position: relative;
  z-index: 2;
}

.cu-form-section {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 20px 11px 2px rgb(157, 157, 157);
  height: 100%;
}

.cu-right-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* background-color: #7c7c7c; */
  background: rgba(221, 221, 221, 0.9);
background: linear-gradient(85deg, rgba(255,255,255,0) 0.6%, rgb(231, 231, 231) 100%);
  filter: blur(6px);
}

@media (max-width: 575px) {

  .cu-left-image-box {
    display: none;
  }
  .cu-content {
    grid-template-columns: 1fr;
  }

  .cu-form-section {
    position: relative;
    background: none;
  }

  .cu-ribbon {
    /* background-color: #fff; */
/* background: linear-gradient(0deg, rgb(231, 231, 231) 0%, rgba(218, 218, 218, 0.827) 0.6%, rgb(231, 231, 231) 100%); */

  }

  .contact-wrapper {
    /* display: none; */
    background: none;
  }

.cu-right-wrapper::before {
  display: none;
}
  .cu-form-section::before {
    /* content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/ContactUs/cu-whole-bg.jpg');
    z-index: -2;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover; */
  }
  
  .cu-form-section::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url('../../assets/images/ContactUs/cu-whole-bg.jpg'); */
    z-index: -1;
    background: rgba(221, 221, 221, 0.9);
    background: linear-gradient(85deg, rgba(255,255,255,0) 0.6%, rgba(231, 231, 231, 0.086) 100%);
      filter: blur(1px);
      z-index: -1;
  }
  
}