.timeline-wrapper {
  position: relative;
  z-index: 1;
}

.timeline-wrapper::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url('../../../assets/images/Timeline/stripBg.svg');
  box-shadow: inset 0 0 30px 30px #fff;
  z-index: -2;
}

.timeline-wrapper::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff2e;

  z-index: -1;
}

.vertical-timeline-element-content {
  background: var(--clr-bg-dark);
  color: #fff;
  border-top: 1px solid var(--clr-bg-dark-medium);
}

.vertical-timeline-element-content-arrow {
  border-right-color: var(--clr-bg-dark);
}

@media (max-width: 989px) {
  .vertical-timeline-element-content {
    padding: 10px 15px;
  }

  .vertical-timeline {
    padding: 1em 0;
  }
}