.link-container .link-div {
  position: relative;
  text-shadow: 1px 1px 2px var(--clr-bg-darkest);
  transition: all 0.5s linear;
}

.link-container .link-div-wrapper {
  transition: all 100ms linear;
}

.link-container .link-div-wrapper:hover {
  transform: scale(1.1);
}

.link-container .link-div-wrapper:hover div::after {
  transform: scale(1);
}

.separator {
 
  width: 2px;
  height: 100%;
  background-color: var(--clr-bg-darkest);
  border-radius: 100vw;
  
}
.link-div-wrapper {
  position: relative;
}

.link-div-wrapper:has(a.active)::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  top: 100%;
  left: 0;
  background: var(--clr-bg-darkest);
  background: radial-gradient(circle, var(--clr-bg-darkest) 0%, rgba(1, 60, 186, 0.57) 50%, rgba(255,255,255,0) 100%);
  z-index: 2;
}