.banner-img {
  /* box-shadow: 0 0 11px 2px #fff; */
  /* box-shadow: 0 0 5px 5px var(--clr-bg-dark), 0 0 10px 10px var(--clr-bg-light) 0 0 15px 15px var(--clr-bg-dark); */
  /* border: 1px solid var(--clr-bg-dark); */
  z-index: 1;
}

.banner-img {
  outline: 20px solid var(--clr-bg-dark);
}
.banner {
  /* background-color: rgba(103, 157, 2, 0.132); */
  position: relative;
  overflow: visible;
}
.banner::before {
  /* background-color: rgba(103, 157, 2, 0.132); */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  background-image: url('../../../assets/images/Banner/bg-curves.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(2px);
}
.banner::after {
  /* background-color: rgba(103, 157, 2, 0.132); */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #eee8e869;
  filter: blur(2px);
}

.banner-right {
  /* background-color: #fff; */
  /* background: rgb(103,157,2); */
  background: linear-gradient(90deg, rgb(191, 225, 126) 0%, rgb(103, 157, 2) 100%);
  /* box-shadow:  0 0 11px 2px rgba(103,157,2,1); */
  /* box-shadow: 0 0 11px 2px rgba(103,157,2,1); */
  z-index: 0;
  position: relative;

  border: 10px solid rgba(103,157,2,1);
  border-left: 0;
  background-color: #f7f5f5;
  overflow: hidden;
}

.right-title {
  font-weight: bolder;
  text-shadow: 2px 2px 6px var(--clr-bg-dark-medium);
}

.deco-banner {

  position: absolute;
    right: -28%;
    bottom: -88%;
    transform: rotate(62deg);
    width: 60%;
}

@media (max-width: 1024px) {
  .h-banner-grid {
    display: grid;
    grid-template-columns: 40vw 1fr;
  }
}

@media (max-width: 989px) {
  .h-banner-grid .banner-right {
    display: none;
  }

  .banner-img {
    box-shadow: 0 0 11px 2px #bdbdbd;
  }

  .h-banner-grid {
    grid-template-columns: 98vw;
  }
  
  .banner-img {
    outline: none;
    
  }
}