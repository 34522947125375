:root {
  /* --clr-bg-dark: #0D539E;
  --clr-bg-dark-medium: #2078d7;
  --clr-bg-light: #fff; */
  
  --clr-bg-darkest: #001B54;
  --clr-bg-dark: #083F88;
  --clr-bg-dark-transp: #083f88d0;
  --clr-bg-dark-medium: #22729c;
  --clr-bg-blue: #0D539E;
  --clr-bg-light: #fff;
  --clr-bg-third: #f9f780;


  --page-container-max-w: 1450px;

  --top-z-index: 2024;

  --banner-bg: #85BF1A;
}

@media (min-width: 576px) and (max-width: 1200px) {
  :root {
    --page-container-max-w: 96vw;
  }
}

@media (max-width: 575px) {
  :root {
    --page-container-max-w: 98vw;
  }
}

@media (max-width: 989px) {
  :root {
    --page-container-max-w: 98vw;
  }
}