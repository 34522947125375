.board-members-wrapper {
  position: relative;
}

.board-members-wrapper::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(79, 127, 155, 0.768032212885154) 69%, var(--clr-bg-dark) 100%);
  opacity: 0.7;
}

.card-grid {
  display: flex;
  max-width: 500px;
  margin-inline: auto;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
}

.card-grid > * {
  flex-basis: 300px;
}

.board-member-img-wrap {
  max-height: 300px;
  object-fit: cover;
}

.board-member-img-wrap img {
  object-fit: cover;
  object-position: top;
}

.card {
  border-radius: 10px;
  width: fit-content;
  position: relative;
  margin-bottom: 10%;
  background: rgb(161, 0, 0);
  background: linear-gradient(180deg, #ecf8ff5a 0%, var(--clr-bg-dark) 100%);

  overflow: hidden;
  display: grid;
  box-shadow: 0 3px 10px 2px #00000033;
}

.info {
  text-align: center;
  color: #fff;
  padding-block: 5px;
}

.board-member-img {
  position: relative;
}

.board-member-img-wrap {
  position: relative;
}

.board-member-img-wrap::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  box-shadow: inset 0 0 10px 2px #fff;

}

.board-member-name {
  text-align: center;
  font-weight: bold;
  color: var(--clr-bg-dark-medium);
  background-color: #fff;
  position: relative;
  border-top: 1px solid var(--clr-bg-dark);
}

.info .subtitle {
  font-size: 0.75rem;
}

@media (max-width: 989px) {


  .card-grid {
    max-width: 80vw;
    margin-inline: auto;
    gap: 1rem;
  }

}

@media (max-width: 450px) {
  .board-member-name {
    font-size: 0.62rem;
    line-height: 2;
  }

  .card-grid {
    max-width: 90vw;
    margin-inline: auto;
    flex-wrap: wrap;
  }

  .card-grid>* {
    flex-basis: 60vw;
    min-width: 60vw;
  }

  .card {
    grid-template-rows: 1fr 20px 20px;

  }
  .info .designation {
    font-size: 0.63rem;
  }
}