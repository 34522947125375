.banner-image {
  object-fit: cover;
  object-position: center 80%;
}

.init-line {
  text-indent: 4rem;
  font-size: 1.3vw;
  color: var(--clr-bg-dark);
  font-weight: bold;
}

.admsn-page-grid {
  display: grid;
  grid-template-columns: 70% 1fr;
  margin-inline: auto;
}

section .title {
  position: relative;
  font-weight: bolder;
  letter-spacing: 10px;
  color: var(--clr-bg-blue);
}

section .title .content {
  background-color: white;
  padding-inline: 20px;
}

section .title::before {
  content: '';
  position: absolute;
  width: 200vw;
  height: 10px;
  bottom: 25px;
  right: -40vw;
  background-color: var(--clr-bg-blue);
  z-index: -1;
}


.why-us-content .line {

  font-weight: bold;
  color: var(--clr-bg-dark);
  font-size: 1.5vw;
  padding-block: 4px;
  position: relative;
  left: 10px;
}

.why-us-content .line::before {
  content: '☛';
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 0;
  left: -1.8rem;
}

.why-us-content .line-wrap:not(:last-child) {
  margin-bottom: 2rem;
}

.why-us-content .subtitle {
  position: relative;
  left: 10px;
}

.ongoing-adm-content .line {

  font-weight: 500;
  color: var(--clr-bg-dark);
  font-size: 1.3vw;
  position: relative;
  left: 10px;
}

.ongoing-adm-content ul {
  list-style-type: decimal;
}


.right-section {
  border-left: 1px solid var(--clr-bg-blue);
  border-right: 1px solid var(--clr-bg-blue);
  /* height: fit-content; */
  position: relative;
  /* right: -22%; */
  box-shadow: -2px 0 11px 1px var(--clr-bg-dark);
  border-top-left-radius: 10px;
  /* border-bottom-left-radius: 10px; */
}

/* .right-section::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 100%;
  background-color: white;
  border-top: 1px solid var(--clr-bg-blue);

} */

.right-section .title {
  font-size: 1.3vw;
  font-weight: 800;
  text-align: center;
  padding-block: 10px;
  background-color: var(--clr-bg-blue);
  color: white;
  border-top-left-radius: 10px;
}

.right-section .content {
  padding: 20px;
}


.downloads-content {
  max-width: 90%;
  margin-right: 0;
  margin-left: auto;
}

.downloads-content .line {

  font-weight: bold;
  color: var(--clr-bg-dark);
  font-size: 1vw;
  padding-block: 4px;
  position: relative;
  left: 10px;
  text-decoration: underline;
  color: blue;
}

.downloads-content .line::before {
  content: '-';
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 4px;
  left: -0.8rem;
}


.downloads-content .line-wrap:not(:last-child) {
  margin-bottom: 0.5rem;
}

.downloads-content .line-wrap {
  transition: all 0.2s linear;
}

.downloads-content .line-wrap:hover {
  transform: translateX(5px) scale(1.05);
}

.ongoing-admsn-grid {
  display: grid;
  grid-template-columns: 1fr 50%;
  gap: 1rem;
}

.admsn-img-box {
  box-shadow: 0 0 8px 2px rgb(194, 194, 194);
  overflow: hidden;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}


@media (max-width: 989px) {
  
  .admsn-page-grid {
    grid-template-columns: 1fr;
  }
  section .title {
    letter-spacing: 5px;
  }

  section .title .content {
    padding-inline: 10px;
  }

  section .title::before {
    height: 6px;
    bottom: 16px;
    right: -53px;
  }

  .why-us-content .line {
    font-size: 1.15rem;
  }

  .why-us-content .subtitle {
    font-size: 0.9rem;
  }

    
  .why-us-content .line-wrap:not(:last-child) {
    margin-bottom: 1.3rem;
  }

  .ongoing-admsn-grid {
    grid-template-rows: 400px auto;
    grid-template-columns: auto auto;
  }

  .oa-area-1 {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .oa-area-2 {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .ongoing-adm-content .line {
    font-size: 0.9rem;
  }

  .right-section {
    right: 0;
    border-top-left-radius: 0;
  }
  
  .right-section .title {
    font-size: 1.3rem;
    border-top-left-radius: 0;
  }

  .downloads-content .line {
    font-size: 0.9rem;
  }
}